import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Heavy Metal Music' },
            { name: 'keywords', content: 'metal, heavy-metal, music' },
          ]}
        >
          <html lang="en" />
        </Helmet>

        <header className="masthead outer">
          <div className="container">
            <div className="row">
             <Header siteTitle={data.site.siteMetadata.title} />
            </div>
          </div>
        </header>

        <div className="content container">
        
          {children}
          
          <div class="bg-purple text-white sm:bg-green md:bg-blue md:text-black lg:bg-red lg:text-white xl:bg-orange xl:text-green">
            <p>Fish</p>
          </div>
        </div>

      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
