import React from 'react'
import { Link } from 'gatsby'

const Header = ({ siteTitle }) => (
  <div className="logo">
    <Link to="/" className="anchor">{siteTitle}</Link>
  </div>
)

export default Header
